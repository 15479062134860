import type React from "react";
import quote from "@assets/images/icons/icon-quote.svg";
import type { ReactNode } from "react";
import Img from "@react/widget/Img";

interface Props {
    align?: string;
    width?: string;
    size?: string;
    fontWeight?: string;
    quoteWrapClass?: string;
    quoteClass?: string;
    children?: string;
}

const Quote: React.FC<Props> = ({ align = "text-left", size = "text-xl md:text-2xl", fontWeight = "font-semibold", width = "", quoteWrapClass = "", quoteClass = "", children = "", ...props }) => {
    return (
        <div className={["mx-auto", align, width, quoteWrapClass].join(" ")} {...props}>
            <Img className="inline-block max-w-[30px] md:max-w-full" src={quote} alt="Quote" width={42} height={30} />
            <div className={["my-4 md:my-7 space-y-4", fontWeight, size, quoteClass].join(" ")} dangerouslySetInnerHTML={{__html: children}}/>
        </div>
    );
};

export default Quote;