import type { PropsWithChildren, ReactNode } from 'react';
import Img from '@react/widget/Img';
import clsx from 'clsx';

const profiles = {
    "xs": "[&_.name]:text-base [&_.position]:text-sm [&_img]:w-[40px]",
    "sm": "[&_.name]:text-base [&_.position]:text-sm [&_img]:w-[70px]",
    "md": "[&_.name]:text-base [&_.position]:text-sm [&_img]:w-[70px] [&_.name]:md:text-xl [&_.position]:md:text-base [&_img]:md:w-[90px]"
};

interface Props extends PropsWithChildren {
    className?: string;
    align?: string;
    valign?: string;
    alt?: string;
    headshot?: Image;
    profile?: keyof typeof profiles;
    infro?: ReactNode;
};

const Author: React.FC<Props> = ({ className = "", align = "text-left", alt = "author", valign = "items-center", headshot, profile = "sm", children, infro,...props }) => {
    return (
        <div className={clsx("flex [&_.name]:font-semibold", align, valign, profiles[profile], className)} {...props}>
            {!!headshot && <Img className={`rounded-full mr-6`} src={headshot} width={70} height={70} alt={alt}/>}
            {infro}
        </div>

    );
};
export default Author;